import React, { useEffect, useState } from "react";
import { Menu, Result } from "antd";
import { AppstoreOutlined, BookOutlined } from "@ant-design/icons";
import Books from "./Books";
import Main from "./Layout";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/useAuth";

const Catelogue = () => {
	const [selectedKey, setSelectedKey] = useState("1");
	const [openKeys, setOpenKeys] = useState([]); 
	const location = useLocation();
	const navigate = useNavigate();
	const [loading,setLoading] = useState(false);
	const [categories, setCategories] = useState([]);
	const { apiurl } = useAuth();

	const handleMenuClick = (e) => {
		setSelectedKey(e.key);
	};

	const fetchCategories = async () => {
		try {
			const response = await fetch(`${apiurl}/categories/filters/`, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (response.ok) {
				const data = await response.json();
				const formattedCategories = formatCategories(data);
				setCategories(formattedCategories);
			} else {
				console.error("Failed to fetch categories:", response.statusText);
			}
		} catch (error) {
			console.error("Error fetching categories:", error);
		}
	};

	const formatCategories = (categoriesData) => {
		const categoryMap = {};

		categoriesData.forEach((category) => {
			const { name, type, items } = category;

			if (!categoryMap[name]) {
				categoryMap[name] = {
					value: name,
					label: name,
					children: [],
				};
			}

			if (!type) {
				items.forEach((item) => {
					if (item.name === name) {
						categoryMap[name].value = item.id;
						categoryMap[name].id = item.id;
					} else {
						categoryMap[name].children.push({
							value: item.id,
							label: item.name,
							main_category: name,
							id: item.id,
						});
					}
				});
			} else {
				let typeNode = categoryMap[name].children.find(
					(child) => child.label === type
				);

				if (!typeNode) {
					typeNode = {
						value: type,
						label: type,
						children: [],
					};
					categoryMap[name].children.push(typeNode);
				}

				items.forEach((item) => {
					typeNode.children.push({
						value: item.id,
						label: item.name,
						main_category: name,
						type: type,
						id: item.id,
					});
				});
			}
		});

		return Object.values(categoryMap);
	};

	const handleCategorySelect = (selectedCategories) => {
		if (window.innerWidth < 768) {
			setSelectedKey(""); 
			setOpenKeys([]);
		}
		if (selectedCategories === null) {
			navigate("/catalogue", { state: { categoryData: null } });
		} else {
			const categoryData = selectedCategories;
			if (location.pathname === "/catalogue") {
				navigate("/catalogue", { state: { categoryData }, replace: true });
			} else {
				navigate("/catalogue", { state: { categoryData } });
			}
		}
	};

	useEffect(() => {
		fetchCategories();
	}, []);

	const menuItems = [
		{
			key: "1",
			icon: <BookOutlined />,
			label: (
				<span>
					<div onClick={() => handleCategorySelect(null)}>Books</div>
				</span>
			),
			children: categories.map((mainCategory) => {
				if (mainCategory.id) {
					return {
						key: mainCategory.id + mainCategory.label + mainCategory.value,
						label: (
							<span
								style={{
									display: "block",
									width: "100%",
									cursor: "pointer",
								}}
								onClick={() => handleCategorySelect([mainCategory.value])}>
								{mainCategory.label}
							</span>
						),
					};
				} else {
					return {
						key: mainCategory.id + mainCategory.label + mainCategory.value,
						label: (
							<span
								style={{
									display: "block",
									width: "100%",
									cursor: "pointer",
								}}>
								{mainCategory.label}
							</span>
						),
						children: mainCategory.children
							? mainCategory.children.map((subCategory) => ({
									key:
										mainCategory.value + subCategory.label + subCategory.value,
									label: (
										<span
											style={{
												display: "block",
												width: "100%",
												cursor: "pointer",
											}}
											onClick={() =>
												handleCategorySelect([
													mainCategory.value,
													subCategory.value,
												])
											}>
											{subCategory.label}
										</span>
									),
									children: subCategory.children
										? subCategory.children.map((item) => ({
												key:
													mainCategory.id +
													item.label +
													subCategory.value +
													mainCategory.value +
													item.value,
												label: (
													<span
														style={{
															display: "block",
															width: "100%",
															cursor: "pointer",
														}}
														onClick={() =>
															handleCategorySelect([
																mainCategory.value,
																subCategory.value,
																item.value,
															])
														}>
														{item.label}
													</span>
												),
										  }))
										: null,
							  }))
							: null,
					};
				}
			}),
		},
		{
			key: "2",
			icon: <AppstoreOutlined />,
			label: "Toys",
		},
	];


	return (
		<Main>
			<div className="custom-container">
				<Menu
					mode="inline"
					theme="light"
					selectedKeys={[selectedKey]}
					openKeys={openKeys} 
					onClick={handleMenuClick}
					onOpenChange={(keys) => setOpenKeys(keys)}
					className="custom-menu"
					items={menuItems}></Menu>
				<div className="custom-content">
					{selectedKey === "2" ? (
						<Result
							status="info"
							title="Content Under Development"
							subTitle="This section is coming soon."
						/>
					) : (
						<Books setLoading={setLoading}/>
					)}
				</div>
			</div>
		</Main>
	);
};

export default Catelogue;
