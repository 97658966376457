import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import {
	Drawer,
	Cascader,
	Button,
	message,
	Pagination,
	Input,
	Result,
} from "antd";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import "./Books.css";
import Loader from "../Loader/Loader";

const { Search } = Input;

const Books = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { apiurl, token } = useAuth();

	const [books, setBooks] = useState([]);
	const [filteredBooks, setFilteredBooks] = useState([]);
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");
	const [pageSize, setPageSize] = useState(10);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalBooks, setTotalBooks] = useState(0);
	const [wishlist, setWishlist] = useState([]);
	const [loading, setLoading] = useState(true);

	const fetchBooks = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/books/`);
			if (!response.ok) throw new Error(`Error: ${response.statusText}`);
			const data = await response.json();
			setBooks(data.data);
			setTotalBooks(data.data.length);
			handleFilterAndSearch(data.data, 1, pageSize);
		} catch (error) {
			message.error("Error fetching books.");
		} finally {
			setTimeout(() => {
				setLoading(false);
			}, 1000);
		}
	};

	const fetchCategories = async () => {
		try {
			const response = await fetch(`${apiurl}/categories/filters/`, {
				headers: {
					"Content-Type": "application/json",
				},
			});
			if (response.ok) {
				const data = await response.json();
				const formattedCategories = formatCategories(data);
				setCategories(formattedCategories);
			}
		} catch (error) {
			console.error("Error fetching categories:", error);
		}
	};

	const formatCategories = (categoriesData) => {
		const categoryMap = {};
		categoriesData.forEach((category) => {
			const { name, type, items } = category;
			if (!categoryMap[name]) {
				categoryMap[name] = {
					value: name,
					label: name,
					children: [],
				};
			}
			if (!type) {
				items.forEach((item) => {
					if (item.name === name) {
						categoryMap[name].value = item.id;
					} else {
						categoryMap[name].children.push({
							value: item.id,
							label: item.name,
							main_category: name,
						});
					}
				});
			} else {
				let typeNode = categoryMap[name].children.find(
					(child) => child.label === type
				);
				if (!typeNode) {
					typeNode = {
						value: type,
						label: type,
						children: [],
					};
					categoryMap[name].children.push(typeNode);
				}
				items.forEach((item) => {
					typeNode.children.push({
						value: item.id,
						label: item.name,
						main_category: name,
					});
				});
			}
		});
		return Object.values(categoryMap);
	};

	const fetchWishlist = async () => {
		try {
			const response = await fetch(`${apiurl}/wishlist/`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
			});
			if (!response.ok) throw new Error(`Error: ${response.statusText}`);
			const data = await response.json();
			setWishlist(data.data);
		} catch (error) {
			console.error("Error fetching wishlist:", error);
		}
	};

	useEffect(() => {
		fetchBooks();
		fetchCategories();
		fetchWishlist();
	}, []);

	useEffect(() => {
		handleFilterAndSearch(books, currentPage, pageSize);
	}, [selectedCategory, currentPage, pageSize, books, searchTerm]);

	const handleCategoryChange = (value) => {
		setSelectedCategory(value || []);
		setCurrentPage(1);
	};

	const handleSearch = (value) => {
		setLoading(true);
		setSelectedCategory([]);
		setSearchTerm(value);
		setTimeout(() => {
			setLoading(false);
		}, 500);
	};

	const handlePageChange = (page, pageSize) => {
		setLoading(true);
		setCurrentPage(page);
		setPageSize(pageSize);
		handleFilterAndSearch(filteredBooks, page, pageSize);
		setLoading(false);
	};

	const handleFilterAndSearch = (booksList, currentPage, pageSize) => {
		let filtered = booksList || books;
		if (searchTerm && typeof searchTerm === "string") {
			const normalizedSearchTerm = searchTerm.replace(/\s+/g, "").toLowerCase();
			filtered = filtered.filter((book) => {
				const normalizedTitle = book.title
					? book.title.replace(/\s+/g, "").toLowerCase()
					: "";
				const normalizedAuthor = book.author
					? book.author.replace(/\s+/g, "").toLowerCase()
					: "";

				return (
					normalizedTitle.includes(normalizedSearchTerm) ||
					normalizedAuthor.includes(normalizedSearchTerm)
				);
			});
		}

		console.log(searchTerm);

		if (selectedCategory.length > 0) {
			const firstItem = selectedCategory[0].toString().toLowerCase();
			const lastItem = selectedCategory[selectedCategory.length - 1]
				.toString()
				.toLowerCase();

			if (selectedCategory.length >= 2) {
				filtered = filtered.filter((book) => {
					const matchesAge1 =
						book.age_preffered &&
						book.age_preffered.toString().toLowerCase() === firstItem;
					const matchesAge2 =
						book.age_preffered2 &&
						book.age_preffered2.toString().toLowerCase() === firstItem;
					const matchesAge = matchesAge1 || matchesAge2;
					const matchesCategory = book.categories?.some(
						(cat) => cat.id && cat.id.toString().toLowerCase() === lastItem
					);
					setLoading(false);
					return matchesAge && matchesCategory;
				});
			} else {
				filtered = filtered.filter((book) => {
					const matchesCategory = book.categories?.some(
						(cat) => cat.id && cat.id.toString().toLowerCase() === firstItem
					);
					setLoading(false);
					return matchesCategory;
				});
			}
		}
		const start = (currentPage - 1) * pageSize;
		const end = start + pageSize;
		setFilteredBooks(filtered.slice(start, end));
		setTotalBooks(filtered.length);
	};

	useEffect(() => {
		const categoryData = location.state?.categoryData;
		if (categoryData) {
			setTimeout(() => {
				setSelectedCategory(categoryData);
			}, 100);
		} else {
			setBooks(books);
		}
	}, [location, books, navigate]);

	const isWishlisted = (bookId) =>
		wishlist.some((item) => item.book.id === bookId);

	const toggleWishlist = async (bookId) => {
		setLoading(true);
		const wishlisted = isWishlisted(bookId);
		const method = wishlisted ? "DELETE" : "POST";
		if (!token) {
			setLoading(false);
			return message.error("Please login to wishlist books.");
		}
		try {
			const response = await fetch(`${apiurl}/wishlist/`, {
				method,
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ id: bookId }),
			});
			const data = await response.json();
			if (response.ok) {
				message.success(data.message);
				fetchWishlist();
				setLoading(false);
			} else {
				setLoading(false);
				message.error("Please login to add to wishlist.");
			}
		} catch (error) {
			setLoading(false);
			message.error("Error toggling wishlist.");
		}
	};

	const renderBookActions = (bookId) => (
		<>
			<Button onClick={() => navigate(`/catalogue/${bookId}`)}>
				View Book
			</Button>
			<Button
				type="primary"
				icon={isWishlisted(bookId) ? <HeartFilled /> : <HeartOutlined />}
				onClick={() => toggleWishlist(bookId)}
			/>
		</>
	);

	if (loading) {
		return (
			<div className="books-container">
				<Loader />
			</div>
		);
	}

	return (
		<div className="books-container">
			<div className="mobile-filter-btn">
				<Search placeholder="Search books" onSearch={handleSearch} />
				<Cascader
					options={categories}
					onChange={handleCategoryChange}
					placeholder="Filter by category"
					className="category-cascader"
				/>
			</div>
			<div className="book-list">
				<div className="book-grid">
					{filteredBooks.length > 0 ? (
						filteredBooks.map((book) => (
							<div key={book.id} className="book-item">
								<div className="book-image">
									<img src={`${apiurl}${book.image}`} alt={book.title} />
								</div>
								<div className="book-details">
									<div>
										<div className="book-title">
											{book.title.length > 30
												? `${book.title.slice(0, 30)}...`
												: book.title}
										</div>
										<div className="book-categories">
											{book.categories.length > 0
												? book.categories
														.map((category) => category.name)
														.join(", ")
												: "No categories"}
										</div>
										<div className="book-author">By {book.author}</div>
										<div className="desc">
											{book.description.slice(0, 120)}...
										</div>
									</div>
									<div className="book-actions">
										{renderBookActions(book.id)}
									</div>
								</div>
							</div>
						))
					) : (
						<div className="books-nt-available">
							<Result
								status="404"
								title="Books Not Available"
								subTitle="Sorry, there are no books available at the moment."
							/>
						</div>
					)}
				</div>
				<Pagination
					className="book-pagination"
					current={currentPage}
					pageSize={pageSize}
					total={totalBooks}
					onChange={handlePageChange}
					showSizeChanger
					pageSizeOptions={["10", "20", "50"]}
				/>
			</div>
		</div>
	);
};

export default Books;
